import {
  Button,
  MenuItem,
  Box,
  Typography,
  Card,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardContent,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import TableHead from "src/components/table-head";
import { IconArrowRight, IconEye } from "@tabler/icons-react";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RequiredStar } from "src/components/required-star";
import { AppState } from "src/store/Store";
import axiosServices from "src/utils/axios";
import CustomFormLabel from "src/utils/theme-elements/CustomFormLabel";
import CustomTextField from "src/utils/theme-elements/CustomTextField";
import { notifyMessage } from "src/utils/toastNotify";
import { nanoid } from "nanoid";
import axios from "axios";
import SportsIcon from "@mui/icons-material/Sports";
import { formatDate } from 'src/utils/basicFormaters';
import FileUpload from "src/components/upload-file/UploadFile";
import { allowedFormats, maxFileSize } from "src/utils/fileUploadConfig";
import { Link, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import APP_ROUTES from "src/routes/routePaths";
import BackLink from "src/components/back-link";
import { resetClubAthleteData } from "src/store/clubRegister/ClubAthleteViewEdit";
import { useDispatch } from "react-redux";
import { resetAthleteData, resetSpecificFields } from "src/store/clubRegister/AthleteFormDataSlice";
import { deleteLocalFormData } from "src/services/localStorage/localServices";

interface ViewUploaded {
  url: string | null;
  type: string | null;
}

function ClubChangeRequestForm() {
  const [clubs, setClubs] = useState<any>([])
  // const [athletes, setAthletes] = useState<any>([])
  const [athleteId, setAthleteId] = useState<number | null>(null)
  const [athleteDetails, setAthleteDetails] = useState<any>({})
  const [currentClubDetails, setCurrentClubDetails] = useState<any>({})
  const [newClub, setNewClub] = useState<any>(null)
  const [transactionId, setTransactionId] = useState("")
  const [clubChangeFees, setClubChangeFees] = useState<any>({
    athlete_club_change_fees: 0
  })
  const [clubName, setClubName] = useState("")
  const [dialogOpen, setDialogOpen] = useState(false)
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false)
  const [paymentUrl, setPaymentUrl] = useState("")
  const [reasonForChange, setReasonForChange] = useState("")
  const [noc, setNoc] = useState<any>(null);
  const [nocUrl, setNocUrl] = useState<ViewUploaded>({ url: null, type: null });
  const [idCard, setIdCard] = useState<any>(null);
  const [idCardUrl, setIdCardUrl] = useState<ViewUploaded>({ url: null, type: null });
  const [returnedPaymentResponse, setReturnedPaymentResponse] = useState("")
  const [clubChangeDetails, setClubChangeDetails] = useState<any>(null)
  const [dialogOpenView, setDialogOpenView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [viewImage, setViewImage] = useState<ViewUploaded>({ url: null, type: null });

  const { role, token } = useSelector((state: AppState) => state.authToken);
  const athlete: any = useSelector((state: AppState) => state.athelete.DistrictAthlete);
  const athletes: any = useSelector((state: AppState) => state.athelete.districtAthleteList);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUpdateAthleteDetails = () => {
    if (role == "district_club_athlete" && token) {
      const decodedToken: any = jwtDecode(token);
      setAthleteId(decodedToken?.userId);
      setClubName(decodedToken?.state);
      setAthleteDetails(athlete);
      setCurrentClubDetails({ club_id: athlete?.clubId, club_name: athlete.clubName });
      // setCurrentClub(athlete.clubName)
    }
    //  else {
    //   const athleteDetails = athletes.filter((athlete: any) => athlete.athlete_id == athleteId)
    //   setAthleteDetails(athleteDetails)
    // }
  };

  // useEffect(() => {
  //   if (clubChangeDetails?.request_status == "Rejected") {
  //     setNewClub(clubChangeDetails?.requested_club_name);
  //     setReasonForChange(clubChangeDetails?.remarks);
  //   }
  // }, [clubChangeDetails]);

  const generateRandomTxnid = () => {
    const newTxnid = nanoid(40);
    setTransactionId(newTxnid);

    return newTxnid;
  };

  const fetchClubsList = async () => {
    try {
      const response = await axiosServices.get("/district/fetch/clubs")
      if (response.data.success) {
        const data = response.data.data
        setClubs(data)
      }
    } catch (error: any) {
      notifyMessage.error(error?.response?.data?.message)
    }
  }

  const fetchClubChangeFees = async () => {
    try {
      const response = await axiosServices.get("/district/configuration/view-club-change-fees")
      if (response.data.success) {
        const data = response.data.data
        setClubChangeFees(data)
      }
    } catch (error: any) {
      notifyMessage.error(error?.response?.data?.message)
    }
  }


  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const checkPaymentStatus = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/check-payment-status/${transactionId}?stateunit=${clubName}`);
      // console.log(response.data.status, "payment status");

      return response.data.status;
    } catch (error) {
      console.error("Error checking payment status:", error);

      return false;
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (clubChangeDetails?.request_status == "Rejected") {
      await handleNext();

      return;
    }

    if (clubChangeFees.athlete_club_change_fees === "0" || clubChangeFees.athlete_club_change_fees === 0) {
      await handleNext();

      return;
    };

    setDialogOpen(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/pay?stateunit=${clubName}`, {
        txnid: generateRandomTxnid(),
        amount: clubChangeFees.athlete_club_change_fees,
        email: athleteDetails?.email,
        phone: athleteDetails?.phone,
        firstname: athleteDetails?.first_name + " " + athleteDetails?.last_name,
        productinfo: "Club Change Request",
        surl: `${process.env.REACT_APP_BASE_URL}/api/payment-response?stateunit=${clubName}`,
        furl: `${process.env.REACT_APP_BASE_URL}/api/payment-response?stateunit=${clubName}`,
      });
      const paymentUrl = response.data;
      setPaymentUrl(paymentUrl);
      setReturnedPaymentResponse(response.data);
    } catch (error: any) {
      console.log("Error posting data to payment API:", error);
    }
  };

  const handlePaymentAndContinue = async () => {
    const paymentWindow = window.open(paymentUrl, "_blank");

    const pollPaymentStatus = async () => {
      try {
        const paymentStatus = await checkPaymentStatus();
        if (paymentStatus === "success") {
          await handleNext();
        } else {
          notifyMessage.error("Payment was not successful. Please try again.");
        }
      } catch (error) {
        console.log("Error checking payment status:", error);
      }
    };

    const intervalId = setInterval(async () => {
      const paymentStatus = await checkPaymentStatus();
      if (paymentStatus) {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        pollPaymentStatus();
      }
    }, 2000);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      if (paymentWindow) {
        paymentWindow.close();
      }
      notifyMessage.error("Payment check timed out. Please try again.");
    }, 120000);

    handleCloseDialog();
  };

  const fetchAtheteChangeRequestDetais = async () => {
    try {
      const response = await axiosServices.get(`/district/athlete/view-club-change-request/${athlete?.id}`)
      if (response.data.success) {
        const data = response.data.data
        setClubChangeDetails(data)
      }
    } catch (error: any) {
      // notifyMessage.error(error?.response?.data?.message)
    }
  }

  const handleNext = async () => {
    try {
      const formData = new FormData();
      formData.append("athlete_id", String(athleteId));
      formData.append("current_club_id", currentClubDetails?.club_id);
      formData.append("requested_club_id", newClub?.clubId);
      formData.append("requested_by", athleteDetails?.userId);
      formData.append("athlete_club_change_fees_configuration_id", clubChangeFees.athlete_club_change_fees_configuration_id);
      formData.append("txnid", transactionId);
      formData.append("reason_for_club_change", reasonForChange);

      if (noc) {
        formData.append("noc", noc);
      }
      if (idCard) {
        formData.append("id_card", idCard);
      }
      let response;
      if (clubChangeDetails?.request_status == "Rejected") {
        response = await axiosServices.put(`/district/athlete/update-request-club-change/${clubChangeDetails?.request_id}`, formData);
      } else {
        response = await axiosServices.post(`/district/athlete/request-club-change`, formData);
      }
      if (response.data.success) {
        notifyMessage.success(response?.data?.message)
        setClubs([])
        setAthleteId(null)
        setAthleteDetails({})
        setCurrentClubDetails({}),
          setNewClub(null)
        setTransactionId(""),
          setClubChangeFees({
            athlete_club_change_fees: 0
          })
        window.history.back();
      }
    } catch (error: any) {
      notifyMessage.error(error?.response?.data?.message)
    }
  }

  const capitalizeWords = (text: any) => {
    try {
      return text.replace(/\b\w/g, (char: any) => char.toUpperCase());

    } catch (error) {
      console.log("Error checking payment status:", error);
    }

  };

  const handleFileUpload = (fileType: "noc" | "idCard") => (files: File[] | null) => {
    if (files && files.length > 0) {
      const uploadedFile = files[0];

      if (fileType === "noc") {
        setNoc(uploadedFile);
      } else if (fileType === "idCard") {
        setIdCard(uploadedFile);
      }
    }
  };

  const generateFileUrl = (file: File | string | null, setUrl: (viewUploaded: ViewUploaded) => void) => {
    if (file instanceof File) {
      const url = URL.createObjectURL(file);
      const type = file.type;
      setUrl({ url, type });

      return () => URL.revokeObjectURL(url);
    } else if (typeof file === 'string') {
      const type = getFileTypeFromUrl(file);
      setUrl({ url: file, type });
    } else {
      setUrl({ url: null, type: null });
    }
  };

  const getFileTypeFromUrl = (url: string): string | null => {
    const extension = url.split('.').pop()?.toLowerCase();

    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchClubsList()
    handleUpdateAthleteDetails()
    fetchClubChangeFees()
    fetchAtheteChangeRequestDetais()
  }, [])

  useEffect(() => {
    generateFileUrl(noc, setNocUrl);
  }, [noc]);

  useEffect(() => {
    generateFileUrl(idCard, setIdCardUrl);
  }, [idCard]);

  const toggleImageDialog = (imageUrl?: string) => {
    if (imageUrl) {
      generateFileUrl(imageUrl, setViewImage);
      setDialogOpenView(true);
    } else {
      setDialogOpenView(false);
      setViewImage({ url: null, type: null });
    }
  };

  const handleOpenNewClubRegister = () => {
    setRegisterDialogOpen(true);
  };

  const handleCloseNewClubRegister = () => {
    setRegisterDialogOpen(false);
  };

  const handleNewClubRegister = () => {
    localStorage.removeItem("slide1club");
    localStorage.removeItem("slide2club");
    dispatch(resetClubAthleteData());
    dispatch(resetAthleteData());
    navigate(`${APP_ROUTES.DISTRICT_ATHLETE_CLUB_CHANGE_REGISTRATION_FORM}`);
    setTimeout(() => {
      window.location.reload();
    }, 300);
    setRegisterDialogOpen(false);
  };

  return (
    <>
      <BackLink title="Back to the Club Change" route={`${APP_ROUTES.DISTRICT_ATHLETE_CLUB_CHANGE}`} />
      <TableHead title={"Club Change Request Form"} />
      {role == "district_club_athlete" && clubChangeDetails && clubChangeDetails.request_status == "Pending" ? (
        <>
          <Box display="flex" justifyContent="center" mt={3} px={1}>
            <Card
              sx={{
                display: "block",
                padding: 4,
                boxShadow: 6,
                borderRadius: 2,
                width: "100%",
                backgroundColor: "#f9fafb",
              }}
            >
              <CardContent>
                <Typography
                  variant="h1"
                  color="#5D87FF"
                  fontWeight="bold"
                  textAlign="center"
                  sx={{
                    background: "#5D87FF"/* "linear-gradient(90deg, #2196f3, #21cbf3)" */,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontSize: "1.8rem",
                  }}
                >
                  Club Change Request Details
                </Typography>

                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={3} sx={{ flexDirection: { xs: 'column', sm: 'row' }, flexWrap: 'wrap' }}>
                  <Grid item xs={12} sm={4} sx={{ flex: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Athlete Name</Typography>
                    <Box sx={{
                      padding: '10px',
                      borderRadius: '8px',
                      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                      border: '1px solid #ddd',
                      '&:hover': {
                        backgroundColor: '#eaeaea',
                        cursor: 'pointer',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      overflowWrap: 'break-word',
                    }}>

                      <Typography sx={{ wordBreak: 'break-word' }}>
                        {`${clubChangeDetails?.first_name} ${clubChangeDetails?.last_name}`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4} sx={{ flex: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Current Club</Typography>
                    <Box sx={{
                      padding: '10px',
                      borderRadius: '8px',
                      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                      border: '1px solid #ddd',
                      '&:hover': {
                        backgroundColor: '#eaeaea',
                        cursor: 'pointer',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      overflowWrap: 'break-word',
                    }}>
                      <Typography sx={{ wordBreak: 'break-word' }}>
                        {capitalizeWords(clubChangeDetails?.current_club_name)}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4} sx={{ flex: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Requested Club</Typography>
                    <Box sx={{
                      padding: '10px',
                      borderRadius: '8px',
                      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                      border: '1px solid #ddd',
                      '&:hover': {
                        backgroundColor: '#eaeaea',
                        cursor: 'pointer',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      overflowWrap: 'break-word',
                    }}>
                      <Typography sx={{ wordBreak: 'break-word' }}>
                        {capitalizeWords(clubChangeDetails?.requested_club_name)}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4} sx={{ flex: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Request Status</Typography>
                    <Box sx={{
                      padding: '10px',
                      borderRadius: '8px',
                      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                      border: '1px solid #ddd',
                      '&:hover': {
                        backgroundColor: '#eaeaea',
                        cursor: 'pointer',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      overflowWrap: 'break-word',
                    }}>
                      <Typography sx={{
                        wordBreak: 'break-word', color:
                          clubChangeDetails?.request_status === "Approved"
                            ? "success.main"
                            : clubChangeDetails?.request_status === "Rejected"
                              ? "error.main"
                              : "warning.main"
                      }}>
                        {capitalizeWords(clubChangeDetails?.request_status)}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} sm={4} sx={{ flex: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Requested Date</Typography>
                    <Box sx={{
                      padding: '10px',
                      borderRadius: '8px',
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                      border: '1px solid #ddd',
                      '&:hover': {
                        backgroundColor: '#eaeaea',
                        cursor: 'pointer',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      overflowWrap: 'break-word',
                      alignItems: 'left',
                    }}>
                      <Tooltip title="View Document">
                        <Box display="flex" alignItems="center">
                          <Typography sx={{ marginRight: 1 }}>{formatDate(clubChangeDetails?.request_date)}</Typography>
                        </Box>
                      </Tooltip>
                    </Box>
                  </Grid> */}

                  <Grid item xs={12} sm={4} sx={{ flex: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>No Objection Certificate (NOC)</Typography>
                    <Box sx={{
                      padding: '0px 10px',
                      borderRadius: '8px',
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                      border: '1px solid #ddd',
                      '&:hover': {
                        backgroundColor: '#eaeaea',
                        cursor: 'pointer',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      overflowWrap: 'break-word',
                      alignItems: 'left',
                    }}>
                      <Tooltip title="View Document">
                        <Box display="flex" alignItems="center">
                          <Typography sx={{ marginRight: 1 }}>View Document</Typography>
                          <IconButton sx={{ cursor: 'pointer' }} onClick={() => toggleImageDialog(clubChangeDetails?.noc)}>
                            <IconEye size="22" stroke={1.4} />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={4} sx={{ flex: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Id Card</Typography>
                    <Box sx={{
                      padding: '0px 10px',
                      borderRadius: '8px',
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                      border: '1px solid #ddd',
                      '&:hover': {
                        backgroundColor: '#eaeaea',
                        cursor: 'pointer',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      overflowWrap: 'break-word',
                      alignItems: 'left',
                    }}>
                      <Tooltip title="View Document">
                        <Box display="flex" alignItems="center">
                          <Typography sx={{ marginRight: 1 }}>View Document</Typography>
                          <IconButton sx={{ cursor: 'pointer' }} onClick={() => toggleImageDialog(clubChangeDetails?.id_card)}>
                            <IconEye size="22" stroke={1.4} />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sx={{ flex: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Reason for Club Change</Typography>
                    <Box sx={{
                      padding: '10px',
                      borderRadius: '8px',
                      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                      border: '1px solid #ddd',
                      '&:hover': {
                        backgroundColor: '#eaeaea',
                        cursor: 'pointer',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      overflowWrap: 'break-word',
                      width: '100%',
                      wordWrap: 'break-word',
                      whiteSpace: 'normal',
                    }}>
                      <Typography sx={{ wordBreak: 'break-word' }}>
                        {clubChangeDetails.remarks || "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>

          <Dialog open={dialogOpenView} onClose={() => toggleImageDialog()} fullWidth maxWidth="sm">
            <DialogTitle>Uploaded Document</DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {viewImage?.url ? (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  {loading && <CircularProgress />}
                  {viewImage?.type === "application/pdf" ? (
                    <iframe
                      src={viewImage?.url}
                      title="Uploaded Document"
                      width="100%"
                      height="500px"
                      style={{ border: "none" }}
                      onLoad={() => setLoading(false)}
                    />
                  ) : (
                    <img
                      src={viewImage?.url}
                      alt="Uploaded Document"
                      style={{
                        maxWidth: "90%",
                        width: "auto",
                        height: "auto",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                      }}
                      onLoad={() => setLoading(false)}
                    />
                  )}
                </Box>
              ) : (
                <Typography>No document available</Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => toggleImageDialog()} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : clubChangeDetails && clubChangeDetails.request_status == "Approved" ? (
        <>
          {/* <Card sx={{ padding: 3 }}>
            <>
              <Typography variant="h6" color="success.main" gutterBottom>
                Your club change request has been approved!
              </Typography>
              <Typography variant="body1" gutterBottom>
                You are now eligible to register with your new club. Please proceed with the registration by clicking the button below.
              </Typography>
            </>
          </Card> */}
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "50vh" }}>
            <Card sx={{ maxWidth: 500, textAlign: "center", p: 3, boxShadow: 3, borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6" color="success.main" gutterBottom>
                  Club Change Request Approved!
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Your club change request has been successfully approved. You are now eligible to register with your new club.
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Please proceed with the registration by clicking the button below.
                </Typography>
                <Box mt={2}>
                  <Button variant="contained" color="primary" onClick={handleOpenNewClubRegister}>
                    Register with New Club
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Dialog open={registerDialogOpen} onClose={handleCloseNewClubRegister} fullWidth maxWidth="sm">
            <DialogTitle>New Club Registration Confirmation</DialogTitle>
            <DialogContent dividers>
              <Box mt={2} p={2} sx={{ backgroundColor: "#fff3cd", borderRadius: 1, borderLeft: "4px solid #ffc107" }}>
                <Typography variant="body2" color="error" fontWeight="bold">
                  Important: Once your registration is submitted, you will no longer have access to this account.
                  New login credentials will be sent to your registered email address.
                </Typography>
                <Typography variant="body2" color="textSecondary" mt={1}>
                  While registering with your new club, you may use either your current email or a new email ID.
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseNewClubRegister} color="error">
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleNewClubRegister}>
                Proceed
              </Button>
            </DialogActions>
          </Dialog>

        </>
      ) : (
        <>
          <Card sx={{ padding: 3 }}>
            {clubChangeDetails?.request_status === "Rejected" && (
              <Box>
                <Typography>1. Your club change request has been <b>rejected</b> by the admin.</Typography>
                {clubChangeDetails?.reject_reason && (
                  <Typography>2. <b>Reason:</b> {clubChangeDetails.reject_reason}</Typography>
                )}
                <Typography>
                  3. You may submit a revised club change request with the necessary updates, and<b> no additional fees </b>will be required.
                </Typography>
              </Box>
            )}

            <Grid container spacing={5} sx={{ marginBottom: 0 }}>
              <Grid item xs={4}>
                <CustomFormLabel>Athlete Name <RequiredStar /></CustomFormLabel>
                <CustomTextField
                  select
                  name="athleteName"
                  fullWidth
                  value={`${athleteDetails.first_name} ${athleteDetails.last_name}`}
                  disabled={role == "district_club_athlete"}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    },
                  }}
                >{role == "district_club_athlete" ? <MenuItem value={`${athleteDetails.first_name} ${athleteDetails.last_name}`}>{`${athleteDetails.first_name} ${athleteDetails.last_name}`}</MenuItem> : <MenuItem value={`${athleteDetails.first_name} ${athleteDetails.last_name}`}>{`${athleteDetails.first_name} ${athleteDetails.last_name}`}</MenuItem>}</CustomTextField>
              </Grid>
              <Grid item xs={4}>
                <CustomFormLabel>Current Club <RequiredStar /></CustomFormLabel>
                <CustomTextField
                  select
                  name="currentClub"
                  fullWidth
                  value={`${currentClubDetails?.club_name}`}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    },
                  }}
                  disabled={true}
                ><MenuItem value={`${currentClubDetails?.club_name}`}>{`${currentClubDetails?.club_name}`}</MenuItem></CustomTextField>
              </Grid>
              <Grid item xs={4}>
                <CustomFormLabel>New Club <RequiredStar /></CustomFormLabel>
                <CustomTextField
                  select
                  name="newClub"
                  fullWidth
                  value={newClub}
                  onChange={(e: any) => setNewClub(e.target.value)}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    },
                  }}
                >
                  {clubs?.filter((club: any) => club.clubName != currentClubDetails?.club_name && club.status == "Approved").map((val: any) => (
                    <MenuItem key={val.clubName} value={val}>{val.clubName}</MenuItem>
                  ))}
                </CustomTextField>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginBottom: 0 }}>
              <Grid item xs={12} >
                <CustomFormLabel>Reason For Club Change</CustomFormLabel>
                <CustomTextField
                  type="string"
                  name="reasonForChange"
                  value={reasonForChange}
                  onChange={(e: any) => setReasonForChange(e.target.value)}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FileUpload
                  title="Current Club NOC"
                  name="noc"
                  viewUploaded={nocUrl}
                  required={true}
                  onFileUpload={handleFileUpload('noc')}
                  maxFileSize={maxFileSize.TwoMB}
                  allowedFormats={allowedFormats.ImageAndPDF}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FileUpload
                  title="Current Club ID card"
                  name="idCard"
                  viewUploaded={idCardUrl}
                  required={true}
                  onFileUpload={handleFileUpload('idCard')}
                  maxFileSize={maxFileSize.FiveMB}
                  allowedFormats={allowedFormats.ImageAndPDF}
                />
              </Grid>

            </Grid>
            <Box sx={{ mt: 2 }}>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 2 }}>
                <Button type="submit" variant="contained" disabled={!newClub || !noc || !idCard} onClick={handleSubmit}>
                  <Typography variant="subtitle2" mr={1}>
                    {role === "district_club_athlete" && clubChangeDetails?.request_status === "Rejected"
                      ? "Update"
                      : clubChangeFees?.athlete_club_change_fees !== 0
                        ? "Pay & Continue"
                        : "Submit"
                    }
                  </Typography>
                  <IconArrowRight size={22} fontWeight={"800"} stroke="#ccc" />
                </Button>
              </Box>
            </Box>
          </Card>

          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>Confirm Payment</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to proceed with the payment?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)} color="error">Close</Button>
              <Button onClick={() => handlePaymentAndContinue()} color="success">Proceed</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

export default ClubChangeRequestForm;
