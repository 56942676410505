import React, { useEffect, useState } from 'react'
import { Box, Button, IconButton, Stack, TableHead, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { IconEye } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import PageContainer from 'src/components/page-container/PageContainer';
import DataTable from 'src/components/table/TableComponent';
import APP_ROUTES from 'src/routes/routePaths';
import axiosServices from 'src/utils/axios';
import { notifyMessage } from 'src/utils/toastNotify';

function AthletesClubChangeRequestList() {
    const navigate = useNavigate();
    const [rows, setRows] = useState<object[]>([])
    const [open, setOpen] = useState(false)

    const fetchClubChangeRequests = async () => {
        try {
            const result = await axiosServices.get(`/district/athlete/view-club-change-request`)
            if (result.data.success) {
                const data = result.data.data
                console.log("data", data);
                setRows(data);
            }
        } catch (error: any) {
            // notifyMessage.error(error?.response?.data?.message)
            setRows([])
        }
    }

    const capitalizeWords = (text: any) => {
        try {
            return text.replace(/\b\w/g, (char: any) => char.toUpperCase());
        } catch (error) {
            console.log("Error checking payment status:", error);
        }
    };


    const handleRequestClubChange = () => {
        navigate(`${APP_ROUTES.DISTRICT_ADMIN_CLUB_CHANGE}/club-change-request-form`)
    }

    const handleClubChangeView = (id: string) => {
        navigate(`${APP_ROUTES.DISTRICT_ADMIN_CLUB_CHANGE}/${id}`)
    }

    useEffect(() => {
        fetchClubChangeRequests()
    }, [])

    const columns: GridColDef[] = [
        {
            field: "srno", headerName: "Sr no.", flex: 0.2,
            // align: 'center',
            // headerAlign: 'center',
        },
        {
            field: "full_name",
            headerName: "Athlete Name",
            flex: 0.6,
            renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params?.row?.full_name} </div>)
        },
        {
            field: "current_club_name",
            headerName: "Current Club Name",
            // headerAlign: 'center',
            flex: 0.5,
            renderCell: (params) => (
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}
                    title={params?.row?.current_club_name}
                >
                    {params?.row?.current_club_name}
                </Box>
            ),
        },
        {
            field: "requested_club_name",
            headerName: "Requested Club Name",
            flex: 0.5,
            // headerAlign: 'center',
            renderCell: (params) => (
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}
                    title={params?.row?.requested_club_name}
                >
                    {params?.row?.requested_club_name}
                </Box>
            ),
        },
        {
            field: "requested_role",
            headerName: "Requested Role",
            flex: 0.5,
            // headerAlign: 'center',
            renderCell: (params) => (
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}
                    title={params?.row?.requested_role}
                >
                    {params?.row?.requested_role}
                </Box>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                const { status } = params.row;

                const getStatusDisplay = () => {
                    // Prioritize "Rejected" without specifying the role
                    if (status === "Rejected") {
                        return <span style={{ color: '#fa896a' }}>Rejected</span>;
                    }

                    // Handle pending statuses
                    if (status === "Pending") {
                        return <span style={{ color: '#ff9800' }}>Pending</span>;
                    }
                    if (status === "Pending") {
                        return (
                            <span style={{ color: status === "Approved" ? '#13deb9' : '#fa896a' }}>
                                {status} by Club
                            </span>
                        );
                    }

                    // Handle approved statuses
                    if (status === "Approved") {
                        return (
                            <span style={{ color: '#13deb9' }}>
                                Approved by District
                            </span>
                        );
                    }

                    // Fallback for unknown statuses
                    return <span>Unknown</span>;
                };

                return (
                    <div
                        style={{
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            lineHeight: '1.2',
                            textAlign: 'center', // Optional, aligns content neatly
                        }}
                    >
                        {getStatusDisplay()}
                    </div>
                );
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 0.3,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                const isBlocked = params.row.is_blocked;
                const reason = params.row.block_reason || "";
                const reason2 = params.row.unblock_reason || "";
                const blockTitle = isBlocked === 1
                    ? `Status: Blocked${reason ? `\nReason: ${reason}` : ''}`
                    : `Status: Unblocked${reason2 ? `\nReason: ${reason2}` : ''}`;
                const blockColor = isBlocked === 1 ? '#fa896a' : '#13deb9';

                return (
                    <>
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}
                        >
                            <Tooltip title="View Change Request">
                                <IconButton sx={{ cursor: "pointer" }} onClick={() => handleClubChangeView(params.row.athlete_id)} >
                                    <IconEye size="22" stroke={1.4} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </>
                );
            }
        }
    ];

    const rowsData: any = rows.map((val: any, index: number) => ({
        id: Number(val.request_id),
        srno: index + 1,
        full_name: `${val.first_name} ${val.last_name}`,
        current_club_name: capitalizeWords(val.current_club_name),
        requested_club_name: capitalizeWords(val.requested_club_name),
        requested_role: capitalizeWords(val.requested_by_role?.replaceAll("_", " ")),
        status: val.request_status,
        athlete_id: val.athlete_id,
    })) || [];


    return (
        <>
            <PageContainer title="Club Change Request" description="This is Change Request page">
                <Stack
                    sx={{
                        background: "#ECF2FF",
                        borderRadius: "6px",
                        padding: "15px 30px",
                        minHeight: "unset",
                    }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >

                    <Typography variant="h5">Club Change Request List</Typography>
                    <Button onClick={() => handleRequestClubChange()} variant="contained" color="primary">
                        Request Club Change
                    </Button>
                </Stack>

                <DataTable rows={rowsData} columns={columns} tableId="table16" />
            </PageContainer>
        </>
    )
}

export default AthletesClubChangeRequestList