import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
} from "@mui/material";
import BackLink from "src/components/back-link";
import TableHead from "src/components/table-head";
import APP_ROUTES from "src/routes/routePaths";
import BlankCard from "src/components/shared/BlankCard";
import CustomTextField from "src/utils/theme-elements/CustomTextField";
import CustomFormLabel from "src/utils/theme-elements/CustomFormLabel";
import { RequiredStar } from "src/components/required-star";
import { PreventWheelChange } from "src/utils/PreventWheelChange";
import axiosServices from "src/utils/axios";
import { notifyMessage } from "src/utils/toastNotify";
import { IconInfoCircle } from "@tabler/icons-react";

interface ClubChangeFeesConfig {
    athlete_club_change_fees_configuration_id: number;
    athlete_club_change_fees: number;
    created_at: string;
    updated_at: string;
    is_active: number;
}

const AthleteClubChangeFeesConfiguration = () => {
    const [clubChangeFeesPlan, setClubChangeFeesPlan] = useState<ClubChangeFeesConfig | null>(null);
    const [fees, setFees] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [feeError, setFeeError] = useState("");
    const [feeOption, setFeeOption] = useState("no");
    const [planId, setPlanId] = useState<number | null>(null);

    useEffect(() => {
        const fetchClubChangeFee = async () => {
            try {
                const { data } = await axiosServices.get("/district/configuration/view-club-change-fees");
                if (data.success) {
                    setClubChangeFeesPlan(data.data);
                } else {
                    notifyMessage.error(data.message);
                }
            } catch (error: any) {
                notifyMessage.error(error?.response?.data?.message);
            }
        };

        fetchClubChangeFee();
    }, []);

    const handleOpenDialog = () => {
        if (clubChangeFeesPlan) {
            setPlanId(clubChangeFeesPlan.athlete_club_change_fees_configuration_id);
            setFees(String(clubChangeFeesPlan.athlete_club_change_fees));
            setFeeOption(clubChangeFeesPlan.athlete_club_change_fees > 0 ? "yes" : "no");
            setOpenDialog(true);
        }
    };

    const handleFeeChange = (fee: string) => {
        if (/^\d{0,9}$/.test(fee)) {
            setFees(fee);
            setFeeError("");
        } else {
            setFeeError("Fee must be less than 1,000,000,000.");
        }
    };

    const handleFeeTypeChange = (fee: string) => {
        if (fee === "no") {
            setFees("0");
        }
        setFeeOption(fee);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setFeeError("");
        setPlanId(null);
        setFees("");
    };

    const handleUpdateFees = async () => {
        const parsedFee = fees.trim() === "" ? NaN : Number(fees);
        if (feeOption === "yes") {
            if (isNaN(parsedFee) || parsedFee < 0) {
                setFeeError("Please enter a valid positive fee amount.");

                return;
            }
        }

        if (planId) {
            try {
                const { data } = await axiosServices.put(`/district/configuration/update-club-change-fees/${planId}`, {
                    athlete_club_change_fees: feeOption === "yes" ? parsedFee : 0,
                });

                if (data.success) {
                    notifyMessage.success(data.message);
                    setClubChangeFeesPlan((prev) => prev && { ...prev, athlete_club_change_fees: parsedFee });
                    handleCloseDialog();
                }
            } catch (error: any) {
                notifyMessage.error(error?.response?.data?.message);
            }
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
        if (!allowedKeys.includes(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <>
            <BackLink title="Back to Configurations Tabs" route={APP_ROUTES.CLUB_ADMIN_CONFIGURAIONS} />
            <BlankCard>
                <TableHead title="Athlete Club Change Fees Configurations" />
                <Box sx={{ p: 3 }}>
                    <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                        Configure the fees for athlete club change requests.
                    </Typography>
                    <Typography variant="body1">
                        Adjust the fees based on district policies for athletes requesting a club change.
                    </Typography>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    border: "1px solid #ccc",
                                    padding: 2,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Box>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        Athlete Club Change Fees
                                        <Tooltip title="Enter 0 if no fee is required." arrow>
                                            <IconButton size="small" sx={{ mb: 1 }} color="secondary">
                                                <IconInfoCircle size="20" stroke={1.4} />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                    <Typography variant="body2">
                                        Set the club change fee for athletes switching clubs.
                                    </Typography>
                                    <Typography variant="h6" sx={{ mt: 2 }}>
                                        {clubChangeFeesPlan?.athlete_club_change_fees
                                            ? `Current Fee: ₹${clubChangeFeesPlan.athlete_club_change_fees}`
                                            : "No fee is charged for club change."}
                                    </Typography>
                                </Box>
                                <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                                    Update
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </BlankCard>

            {/* Dialog for Fee Update */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Athlete Club Change Fee</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Do you want to charge a fee for athlete club changes?
                    </Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row value={feeOption} onChange={(e: any) => handleFeeTypeChange(e.target.value)}>
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    {feeOption === "yes" && (
                        <>
                            <CustomFormLabel sx={{ mt: 2 }}>
                                New Fee
                                <RequiredStar />
                            </CustomFormLabel>
                            <CustomTextField
                                type="number"
                                fullWidth
                                value={fees}
                                onChange={(e: any) => handleFeeChange(e.target.value)}
                                onWheel={PreventWheelChange}
                                onKeyDown={handleKeyPress}
                                error={Boolean(feeError)}
                                helperText={feeError}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions sx={{ mb: 2, mr: 2 }}>
                    <Button onClick={handleCloseDialog} color="error">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateFees} variant="contained" color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AthleteClubChangeFeesConfiguration;
