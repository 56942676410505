import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface DetailConfigData {
    comp_id: number;
    event_id: number;
    no_of_lanes: number;
    start_date: string;
    end_date: string;
    preparation_time: number;
    event_time: number;
    change_over_time: number;
    club_id: number;
    defective_lanes?: string; // Comma-separated lane numbers
    reserved_lanes?: string; // Comma-separated lane numbers
}

interface DetailConfigState {
    config: DetailConfigData | null;
    loading: boolean;
    error: string | null;
}

// Initial State
const initialState: DetailConfigState = {
    config: null,
    loading: false,
    error: null,
};

// Async Thunk for API call
export const saveDetailConfig = createAsyncThunk(
    "detailConfig/save",
    async (configData: DetailConfigData, { rejectWithValue }) => {
        try {
            const response = await axios.post("/api/detail-config", configData);

            return response.data;
        } catch (error: any) {

            return rejectWithValue(error.response?.data || "Something went wrong");
        }
    }
);

const detailConfigSlice = createSlice({
    name: "detailConfig",
    initialState,
    reducers: {
        setConfigData: (state, action: PayloadAction<DetailConfigData>) => {
            state.config = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveDetailConfig.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(saveDetailConfig.fulfilled, (state, action: PayloadAction<DetailConfigData>) => {
                state.loading = false;
                state.config = action.payload;
            })
            .addCase(saveDetailConfig.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { setConfigData } = detailConfigSlice.actions;
export default detailConfigSlice.reducer;
