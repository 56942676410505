import { Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, TableHead, TextField, Tooltip, Typography } from '@mui/material'
import { IconEye } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import BackLink from 'src/components/back-link'
import APP_ROUTES from 'src/routes/routePaths'
import { AppState } from 'src/store/Store'
import axiosServices from 'src/utils/axios'
import { formatDate } from 'src/utils/basicFormaters'
import CustomTextField from 'src/utils/theme-elements/CustomTextField'
import { notifyMessage } from 'src/utils/toastNotify'

interface ViewUploaded {
    url: string | null,
    type: string | null,
}

const AthleteClubChangeRequest = () => {
    const { id } = useParams()
    const [clubChangeDetails, setClubChangeDetails] = useState<any>(null)
    const [dialogOpenView, setDialogOpenView] = useState(false)
    const [loading, setLoading] = useState(true);
    const [viewImage, setViewImage] = useState<ViewUploaded>({ url: null, type: null });
    const [isApproveModalOpen, setApproveModalOpen] = useState(false);
    const [isRejectModalOpen, setRejectModalOpen] = useState(false);
    const [rejectReason, setRejectReason] = useState("");
    const [rejectReasonError, setRejectReasonError] = useState("");

    const fetchAtheteChangeRequestDetais = async () => {
        try {
            const response = await axiosServices.get(`/district/athlete/view-club-change-request/${id}`)
            if (response.data.success) {
                const details = response.data.data
                setClubChangeDetails(details);
                console.log(details);
            }
        } catch (error: any) {
            notifyMessage.error(error?.response?.data?.message)
        }
    }

    const capitalizeWords = (text: any) => {
        try {
            return text?.replace(/\b\w/g, (char: any) => char.toUpperCase());

        } catch (error) {
            console.error("Error checking payment status:", error);
        }
    };

    useEffect(() => {
        fetchAtheteChangeRequestDetais()
    }, []);

    const generateFileUrl = (file: File | string | null, setUrl: (viewUploaded: ViewUploaded) => void) => {
        if (file instanceof File) {
            const url = URL.createObjectURL(file);
            const type = file.type;
            setUrl({ url, type });

            return () => URL.revokeObjectURL(url);
        } else if (typeof file === 'string') {
            const type = getFileTypeFromUrl(file);
            setUrl({ url: file, type });
        } else {
            setUrl({ url: null, type: null });
        }
    };

    const getFileTypeFromUrl = (url: string): string | null => {
        const extension = url.split('.').pop()?.toLowerCase();

        switch (extension) {
            case 'pdf':
                return 'application/pdf';
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            default:
                return null;
        }
    };

    const toggleImageDialog = (imageUrl?: string, isClosing = false) => {
        if (isClosing) {
            setDialogOpenView(false);
            setViewImage({ url: null, type: null });

            return;
        }

        if (imageUrl) {
            generateFileUrl(imageUrl, setViewImage);
            setDialogOpenView(true);
        } else {
            notifyMessage.warning("File not available!");
        }
    };


    const handleOpenRejectModal = () => {
        setRejectModalOpen(true);
    };

    const handleCloseRejectModal = () => {
        setRejectModalOpen(false);
        setRejectReason("");
        setRejectReasonError("");
    };

    const handleOpenApproveModal = () => {
        setApproveModalOpen(true);
    }

    const handleCloseApproveModal = () => {
        setApproveModalOpen(false);
    };

    const handleReject = async () => {
        if (rejectReason.trim() === "") {
            setRejectReasonError("Please provide a reason for rejecting.");

            return;
        };
        const data = {
            "request_status": "Rejected",
            "reject_reason": rejectReason,
        };

        try {
            const response = await axiosServices.patch(`/district/athlete/update-club-change-status/${clubChangeDetails?.request_id}`, data);
            console.log("Rejection Response", response);
            notifyMessage.success(response?.data?.message);
            if (response?.data?.success) {
                fetchAtheteChangeRequestDetais();
                handleCloseRejectModal();
            };
        } catch (error: any) {
            console.error("Error Rejection Request:", error);
        }
    };

    const handleApprove = async () => {
        const data = {
            "request_status": "Approved",
        };

        try {
            const response = await axiosServices.patch(`/district/athlete/update-club-change-status/${clubChangeDetails?.request_id}`, data);
            console.log("Approval Response", response);
            notifyMessage.success(response?.data?.message);
            if (response?.data?.success) {
                fetchAtheteChangeRequestDetais();
                handleCloseApproveModal();
            }
        } catch (error: any) {
            console.error("Error Approving Request:", error);
        }
    }

    return (
        <>
            <BackLink title="Back to the Club Change List" route={`${APP_ROUTES.DISTRICT_ADMIN_CLUB_CHANGE}`} />
            <TableHead title={"Club Change Request Form"} />
            <Box display="flex" justifyContent="center" mt={3} px={1}>
                <Card
                    sx={{
                        display: "block",
                        padding: 4,
                        boxShadow: 6,
                        borderRadius: 2,
                        width: "100%",
                        backgroundColor: "#f9fafb",
                    }}
                >
                    <CardContent>
                        <Typography
                            variant="h1"
                            color="#5D87FF"
                            fontWeight="bold"
                            textAlign="center"
                            sx={{
                                background: "#5D87FF",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                fontSize: "1.8rem",
                            }}
                        >
                            Club Change Request Details
                        </Typography>

                        <Divider sx={{ mb: 2 }} />

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                                    Athlete Name
                                </Typography>
                                <Box sx={{
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                }}>
                                    <Typography>{`${clubChangeDetails?.first_name} ${clubChangeDetails?.last_name}`}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                                    Current Club
                                </Typography>
                                <Box sx={{
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                }}>
                                    <Typography>{capitalizeWords(clubChangeDetails?.current_club_name)}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                                    Requested Club
                                </Typography>
                                <Box sx={{
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                }}>
                                    <Typography>{capitalizeWords(clubChangeDetails?.requested_club_name)}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                                    Request Status
                                </Typography>
                                <Box sx={{
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                }}>
                                    <Typography
                                        sx={{
                                            color:
                                                clubChangeDetails?.request_status === "Approved"
                                                    ? "success.main"
                                                    : clubChangeDetails?.request_status === "Rejected"
                                                        ? "error.main"
                                                        : "warning.main",
                                        }}
                                    >
                                        {capitalizeWords(clubChangeDetails?.request_status)}
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* <Grid item xs={12} sm={4}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                                    Requested Date
                                </Typography>
                                <Box sx={{
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                }}>
                                    <Typography>{formatDate(clubChangeDetails?.request_date)}</Typography>
                                </Box>
                            </Grid> */}

                            <Grid item xs={12} sm={4} sx={{ flex: 1 }}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                                    No Objection Certificate (NOC)
                                </Typography>
                                <Box sx={{
                                    padding: '10px 10px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                    alignItems: 'left',
                                }}>
                                    <Tooltip title="View Document">
                                        <Box display="flex" alignItems="center">
                                            <Typography sx={{ marginRight: 1 }}>View Document</Typography>
                                            <IconButton sx={{ cursor: "pointer", padding: "0px" }} onClick={() => toggleImageDialog(clubChangeDetails?.noc)}>
                                                <IconEye size="22" stroke={1.8} />
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4} sx={{ flex: 1 }}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                                    ID Card
                                </Typography>
                                <Box sx={{
                                    padding: '10px 10px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                    alignItems: 'left',
                                }}>
                                    <Tooltip title="View Document">
                                        <Box display="flex" alignItems="center">
                                            <Typography sx={{ marginRight: 1 }}>View Document</Typography>
                                            <IconButton sx={{ cursor: "pointer", padding: "0px" }} onClick={() => toggleImageDialog(clubChangeDetails?.id_card)}>
                                                <IconEye size="22" stroke={1.8} />
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                                    Reason for Club Change
                                </Typography>
                                <Box sx={{
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                }}>
                                    <Typography>{clubChangeDetails?.remarks || "N/A"}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                                    District Admin Remark
                                </Typography>
                                <Box sx={{
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                }}>
                                    <Typography>{clubChangeDetails?.reason_for_club_change || "N/A"}</Typography>
                                </Box>
                            </Grid>

                        </Grid>
                    </CardContent>

                    {/* {clubChangeDetails?.request_status !== "Approved" ? ( */}
                    <Box sx={{ display: "flex", justifyContent: "center", gap: 2, p: 2 }}>
                        <Button
                            variant="contained"
                            color="error"
                            disabled={clubChangeDetails?.request_status === "Rejected"}
                            sx={{ minWidth: 120 }}
                            onClick={() => handleOpenRejectModal()}
                        >
                            Reject
                        </Button>

                        <Button
                            variant="contained"
                            color="success"
                            disabled={clubChangeDetails?.request_status === "Approved"}
                            sx={{ minWidth: 120 }}
                            onClick={() => handleOpenApproveModal()}
                        >
                            Approve
                        </Button>
                    </Box>

                    {/* ) : <>
                    </>} */}

                </Card>
            </Box>

            <Dialog open={dialogOpenView} onClose={() => toggleImageDialog(undefined, true)} fullWidth maxWidth="sm">
                <DialogTitle>Uploaded Document</DialogTitle>
                <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {viewImage?.url ? (
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            {loading && <CircularProgress />}
                            {viewImage?.type === "application/pdf" ? (
                                <iframe
                                    src={viewImage?.url}
                                    title="Uploaded Document"
                                    width="100%"
                                    height="500px"
                                    style={{ border: "none" }}
                                    onLoad={() => setLoading(false)}
                                />
                            ) : (
                                <img
                                    src={viewImage?.url}
                                    alt="Uploaded Document"
                                    style={{
                                        maxWidth: "90%",
                                        width: "auto",
                                        height: "auto",
                                        borderRadius: "8px",
                                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                                    }}
                                    onLoad={() => setLoading(false)}
                                />
                            )}
                        </Box>
                    ) : (
                        <Typography>No document available</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleImageDialog(undefined, true)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isApproveModalOpen} onClose={handleCloseApproveModal}>
                <DialogTitle>Approval Confirmation</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to approve this request?</Typography>
                </DialogContent>
                <DialogActions sx={{ mb: "15px", mr: "15px" }}>
                    <Button onClick={handleCloseApproveModal} color="error">
                        Cancel
                    </Button>
                    <Button onClick={handleApprove} color="primary">
                        Approve
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isRejectModalOpen} onClose={handleCloseRejectModal} fullWidth maxWidth="sm">
                <DialogTitle>Enter Reject Reason</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please provide a reason for rejecting:</DialogContentText>
                    <CustomTextField
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        value={rejectReason}
                        onChange={(e: any) => {
                            setRejectReason(e.target.value);
                            setRejectReasonError("");
                        }}
                        error={Boolean(rejectReasonError)}
                        helperText={rejectReasonError}
                    />
                </DialogContent>
                <DialogActions sx={{ mb: "15px", mr: "15px" }}>
                    <Button onClick={handleCloseRejectModal} color="error">
                        Cancel
                    </Button>
                    <Button onClick={handleReject} color="primary">
                        Reject
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}

export default AthleteClubChangeRequest