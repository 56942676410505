import { takeLatest, call, put } from 'redux-saga/effects';
import {
  fetchTenantByIdStart,
  fetchTenantByIdFailure,
  fetchTenantByIdSuccess,
  fetchTenantListFailure,
  fetchTenantListStart,
  fetchTenantListSuccess,
  addTenantRequest,
  addTenantSuccess,
  addTenantFailure,
  editTenantFailure,
  editTenantSuccess,
  editTenantRequest,
  editDistrictTenantRequest,
  editDistrictTenantSuccess,
  editDistrictTenantFailure,
  updateTenantStatusRequest,
  updateTenantStatusSuccess,
  updateTenantStatusFailure,
  getRoleAndPermissionRequest,
  getRoleAndPermissionSuccess,
  getRoleAndPermissionFailure,
  updateTenantRoleAndPermissionRequest,
  updateTenantRoleAndPermissionSuccess,
  updateTenantRoleAndPermissionFailure,
  sendEmailToTenantRequest,
  sendEmailToTenantSuccess,
  sendEmailToTenantFailure,
  getSubscriptionAndPlanSuccess,
  getSubscriptionAndPlanFailure,
  getSubscriptionAndPlanRequest,
  checkStateUniqueNameSuccess,
  checkStateUniqueNameFailure,
  checkStateUniqueNameRequest,
  fetchClubAndDraListRequest,
  fetchClubAndDraListFailure,
  fetchClubAndDraListSuccess,
  fetchDistrictClubByIdStart,
  fetchDistrictClubByIdSuccess,
  fetchDistrictClubByIdFailure,
} from '../reducers/TenentSlice';
import axiosServices from 'src/utils/axios';
import { notifyMessage } from 'src/utils/toastNotify';
import { jwtDecode } from 'jwt-decode';





function* fetchTenantListSaga(): Generator<any, void, any> {
  try {
    const response = yield call(() =>
      axiosServices.get('/district/fetch/clubs')
    );
    yield put(fetchTenantListSuccess(response.data.data));
  } catch (error: any) {
    yield put(fetchTenantListFailure(error.message));
  }
}

function* fetchClubAndDraListSaga(): Generator<any, void, any> {
  try {
    const response = yield call(() =>
      axiosServices.get('/tenants/club-dra-listing')
    );
    yield put(fetchClubAndDraListSuccess(response.data));
  } catch (error: any) {
    yield put(fetchClubAndDraListFailure(error.message));
  }
}

function* fetchTenantByIdSaga(action: any): Generator<any, void, any> {
  const id = action.payload;
  try {
    const response = yield call(() =>
      axiosServices.get(`/tenants/get-tenant/${id}`)
    );
    // console.log(response,"ress")
    yield put(fetchTenantByIdSuccess(response.data.tenant));
  } catch (error: any) {

    yield put(fetchTenantByIdFailure(error.message));
  }
}

function* fetchDistrictClubByIdSaga(action: any): Generator<any, void, any> {
  const { id, districtName } = action.payload;
  try {
    // Build the URL with optional districtName
    const url = districtName
      ? `/district/fetch/clubs/${id}?districtName=${districtName}`
      : `/district/fetch/clubs/${id}`;

    const response = yield call(() =>
      axiosServices.get(url)
    );

    console.log(response, "response");
    yield put(fetchDistrictClubByIdSuccess(response.data.data));
  } catch (error: any) {
    yield put(fetchDistrictClubByIdFailure(error.message));
  }
}

function* checkUniqueStateNameSaga(action: any): Generator<any, void, any> {
  const state = action.payload;
  try {
    const response = yield call(() =>
      axiosServices.get(`/tenants/check-unique-state/${state}`)
    );

    yield put(checkStateUniqueNameSuccess(response));

  } catch (error: any) {

    yield put(checkStateUniqueNameFailure(error.message));
  }
}


function* addTenantDataSaga(action: any): Generator<any, void, any> {
  const data = action.payload;
  try {
    const response = yield call(() => axiosServices.post(`/tenants/create`, data));

    const serializableData = {
      status: response.status,
      data: response.data,
    };

    yield put(addTenantSuccess(serializableData));

    if (response.status === 201) {
      notifyMessage.success(response.data.message);
    } else {
      notifyMessage.error("Something Went Wrong!!");
    }

    return response.data;
  } catch (error: any) {
    yield put(addTenantFailure(error.response.data.message));
    notifyMessage.error(error.response.data.message);
    throw error;
  }
}

function* editTenantDataSaga(action: any): Generator<any, void, any> {
  const { formData, id } = action.payload;
  const token = localStorage.getItem("accessToken");

  // if (token) {
  try {
    // const decodedToken: any = jwtDecode(token);

    // let response;
    // if (decodedToken.isClub) {
    const response = yield call(() => axiosServices.put(`${process.env.REACT_APP_BASE_URL}/tenants/edit-tenant/${id}`, formData));
    // } else { // district admin edit api
    // response = yield call(() => axiosServices.put(`${process.env.REACT_APP_BASE_URL}/district/register/update-club/${id}`, formData)); // http://192.168.1.101:5000/v1
    // }

    yield put(editTenantSuccess(response));
    if (response.status === 200 || response.data.success) {
      notifyMessage.success(response.data.message)
    }
    else {
      notifyMessage.error("Something Went Wrong!!")
    }
  }
  catch (error: any) {

    yield put(editTenantFailure(error.message));
    notifyMessage.error(error.message)
  }
  // }
}

function* editDistrictTenantDataSaga(action: any): Generator<any, void, any> {
  const { formData, id } = action.payload;

  try {
    const response = yield call(() => axiosServices.put(`${process.env.REACT_APP_BASE_URL}/district/register/update-club/${id}`, formData));

    yield put(editDistrictTenantSuccess(response));
    if (response.status === 200 || response.data.success) {
      notifyMessage.success(response.data.message)
    }
    else {
      notifyMessage.error("Something Went Wrong!!")
    }
  }
  catch (error: any) {

    yield put(editDistrictTenantFailure(error.message));
    notifyMessage.error(error.message)
  }
}

function* updateUserStatusSaga(action: any): Generator<any, void, any> {
  const { data, id } = action.payload;

  try {
    const response = yield call(() =>
      axiosServices.post(`/district/register/approve-club/${id}`,
        data
      )
    );

    yield put(updateTenantStatusSuccess(response));
    if (response.status === 201) {
      notifyMessage.success(response.data.message);
      yield put(fetchDistrictClubByIdStart({ id: id }));
    }
  } catch (error: any) {
    yield put(updateTenantStatusFailure(error));
    notifyMessage.error(error.message)
    // notifyMessage.error("You Didn't Edit Anything")
  }
}

function* gettenantRoleAndPermissionByIdSaga(action: any): Generator<any, void, any> {
  const id = action.payload;
  try {
    const response = yield call(() =>
      axiosServices.get(`/tenants/role-and-permission/${id}`)
    );

    yield put(getRoleAndPermissionSuccess(response.data.roleAndPermissions));
    // if (response.status === 200) {
    //   // console.log("edited done ", response);
    //   // notifyMessage.success(response.data.message);
    // }
  } catch (error) {

    yield put(getRoleAndPermissionFailure(error));
    // notifyMessage.error("You Didn't Edit Anything")
  }
}

function* updateTenantRoleAndPermissionSaga(action: any): Generator<any, void, any> {
  const { data, id } = action.payload;

  try {
    const response = yield call(() =>
      axiosServices.put(`/tenants/update-role-and-permission/${id}`, {
        roleAndPermissions: data.roleAndPermissions,
      })
    );

    yield put(updateTenantRoleAndPermissionSuccess(response));
    if (response.status === 201) {
      notifyMessage.success(response.data.message);
    }
  } catch (error) {

    yield put(updateTenantRoleAndPermissionFailure(error));
    // notifyMessage.error("You Didn't Edit Anything")
  }
}

function* sendEmailToTenantSaga(action: any): Generator<any, void, any> {
  const { userEmail, associatedWith, fullName } = action.payload;

  // Chnage API after backend change
  try {
    const response = yield call(() =>
      axiosServices.post(`/district/invite/invite-club-email`, {
        userEmail,
        associatedWith,
        fullName
      })
    );

    yield put(sendEmailToTenantSuccess(response));
    if (response.status === 200) {
      notifyMessage.success(response.data.message);
    }
  } catch (error) {

    yield put(sendEmailToTenantFailure(error));
  }
}


function* getSubscriptionAndPlanSaga(action: any): Generator<any, void, any> {
  try {
    const response = yield call(() =>
      axiosServices.get('/tenants/subscription-and-plan')
    );

    yield put(getSubscriptionAndPlanSuccess(response.data.subscriptionAndPlan));

  } catch (error) {
    yield put(getSubscriptionAndPlanFailure(error));
  }
}
export function* watchFetchTenantList() {
  yield takeLatest(fetchTenantListStart.type, fetchTenantListSaga);
}
export function* watchFetchTenantById() {
  yield takeLatest(fetchTenantByIdStart.type, fetchTenantByIdSaga);
}
export function* watchFetchDistrictClubById() {
  yield takeLatest(fetchDistrictClubByIdStart.type, fetchDistrictClubByIdSaga);
}
export function* watchPostTenant() {
  yield takeLatest(addTenantRequest.type, addTenantDataSaga);
}
export function* watcheditTenant() {
  yield takeLatest(editTenantRequest.type, editTenantDataSaga);
}
export function* watcheditDistrictTenant() {
  yield takeLatest(editDistrictTenantRequest.type, editDistrictTenantDataSaga);
}
export function* watcheupdateUserStatusTenant() {
  yield takeLatest(updateTenantStatusRequest.type, updateUserStatusSaga);
}
export function* watchGetRoleAndPermissionById() {
  yield takeLatest(getRoleAndPermissionRequest.type, gettenantRoleAndPermissionByIdSaga);
}
export function* watchUpdateRoleAndPermissionById() {
  yield takeLatest(updateTenantRoleAndPermissionRequest.type, updateTenantRoleAndPermissionSaga);
}
export function* watchSendEmailToTenant() {
  yield takeLatest(sendEmailToTenantRequest.type, sendEmailToTenantSaga);
}
export function* watchGetSubscriptionAndPlan() {
  yield takeLatest(getSubscriptionAndPlanRequest.type, getSubscriptionAndPlanSaga);
}

export function* watchCheckStateUniqueName() {
  yield takeLatest(checkStateUniqueNameRequest.type, checkUniqueStateNameSaga);
}
export function* watchFetchClubAndDraList() {
  yield takeLatest(fetchClubAndDraListRequest.type, fetchClubAndDraListSaga);
}