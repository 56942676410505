// import axios, { AxiosError, AxiosRequestConfig } from "axios";
// import Cookies from "js-cookie";
// import { notifyMessage } from "./toastNotify";
// import { getClaimsFromToken } from "src/services/token/verifyToken";
// import { jwtDecode } from "jwt-decode";

// const isPageReload = () => {
//   const navigation = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;

//   return navigation?.type === "reload";
// };

// const isAuthenticated = () => {

//   return !!Cookies.get("accessToken");
// };

// // Function to refresh the token
// async function refreshToken() {
//   const refreshToken = Cookies.get('refreshToken');
//   const { userId }: any = getClaimsFromToken(refreshToken);

//   if (!refreshToken || !userId) {
//     window.location.href = '/auth/login';
//   }

//   const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/refresh-token`, {
//     refreshToken,
//     userId,
//   });

//   // console.log(response, "response of refresh token --");

//   const newAccessToken = response.data.accessToken;
//   localStorage.setItem('accessToken', newAccessToken);

//   const endTime = new Date().getTime();
//   // console.log('After API call to refresh token:', endTime);

//   return newAccessToken;
// }

// // Function to set a timer for token refresh every 10 minutes
// function setRefreshTokenTimer() {

//   return setInterval(async () => {
//     try {
//       await refreshToken();
//     } catch (error) {
//       console.error('Error refreshing token:', error);
//     }
//   }, 10 * 60 * 1000);
// }

// const axiosServices = axios.create({
//   baseURL: process.env.REACT_APP_BASE_URL,
// });

// let refreshTokenTimer: any;

// // Request interceptor to attach Authorization header
// axiosServices.interceptors.request.use(
//   (config) => {
//     const accessToken = localStorage.getItem('accessToken');

//     if (accessToken) {
//       config.headers = config.headers || {};
//       config.headers.Authorization = `Bearer ${accessToken}`;
//     }

//     return config;
//   },
//   (error) => {
//     if (!isPageReload()) {
//       window.location.href = "/auth/login";
//     }

//     return Promise.reject(error);
//   }
// );

// // Response interceptor to handle token refresh on 401 errors
// axiosServices.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     if (error.response && error.response.status === 401) {
//       try {
//         const newToken = await refreshToken();
//         error.config.headers['Authorization'] = `Bearer ${newToken}`;

//         return axiosServices(error.config);
//       } catch (refreshError) {

//         return Promise.reject(refreshError);
//       }
//     }

//     return Promise.reject(error);
//   }
// );

// export function startTokenRefreshTimer() {
//   refreshTokenTimer = setRefreshTokenTimer();

//   const startTime = new Date().getTime();
//   // console.log(refreshTokenTimer, "Timer of token start --", startTime, "Start Time --")

// }

// export function stopTokenRefreshTimer() {
//   clearInterval(refreshTokenTimer);
//   // console.log("Timer of refresh-token destroyed --")

// }

// if (!isPageReload() && isAuthenticated()) {
//   startTokenRefreshTimer();
// }

// export default axiosServices;






// import axios, { AxiosError, AxiosRequestConfig } from "axios";
// import Cookies from "js-cookie";
// import { notifyMessage } from "./toastNotify";
// import { getClaimsFromToken } from "src/services/token/verifyToken";
// import { jwtDecode } from "jwt-decode";
// import store from "../store/Store";

// const axiosServices = axios.create({
//   baseURL: process.env.REACT_APP_BASE_URL,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// // Define excluded URL patterns
// const excludedUrlPatterns = [
//   /^https?:\/\/(?:[a-zA-Z0-9.-]+|localhost)(?::\d+)?\/admin\/v1\/tenants\/create$/,
//   /^https?:\/\/(?:[a-zA-Z0-9.-]+|localhost)(?::\d+)?\/admin\/v1\/tenants\/get-tenant\/\d+$/,
//   /^https?:\/\/(?:[a-zA-Z0-9.-]+|localhost)(?::\d+)?\/tenants\/edit-tenant\/[a-zA-Z0-9]+$/,
//   /^https?:\/\/(?:[a-zA-Z0-9.-]+|localhost)(?::\d+)?\/admin\/v1\/tenants\/edit-tenant\/\d+$/,
//   /^https?:\/\/[^\/]+\/sa\/create\/club(?:\?expiration=.*)?$/,
//   /^https?:\/\/[^\/]+\/sa\/edit\/club(?:\/\d+)?(?:\?expiration=.*)?$/,
//   /^https?:\/\/[^\/]+\/sa\/create\/district(?:\?expiration=.*)?$/,
//   /^https?:\/\/[^\/]+\/sa\/edit\/district(?:\/\d+)?(?:\?expiration=.*)?$/,
// ];

// // Attach Authorization header unless URL is excluded
// const setTokenInHeaders = (config: AxiosRequestConfig) => {
//   const isExcludedUrl = excludedUrlPatterns.some((pattern) => pattern.test(config.url || ""));

//   if (!isExcludedUrl) {
//     const jwtToken = localStorage.getItem("accessToken") || "";
//     if (!jwtToken) {
//       console.error("Missing JWT token in cookie");
//       throw new Error("Missing token");
//     }
//     config.headers = config.headers || {};
//     config.headers.Authorization = `Bearer ${jwtToken}`;
//     config.headers["Content-Type"] = "application/json";
//   }

//   return config;
// };

// let refreshTimer: NodeJS.Timeout | null = null;
// refreshTimer = null;

// const isPageReload = () => {
//   const navigation = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;

//   return navigation?.type === "reload";
// };

// const isAuthenticated = () => {
//   return !!Cookies.get("accessToken");
// };

// function getExpirationTimeFromToken(token: string): number {
//   const decodedToken: any = jwtDecode(token);
//   if (decodedToken.iat) {
//     const expiresInMilliseconds = decodedToken.expiresIn ? parseDurationToMilliseconds(decodedToken.expiresIn) : 0;

//     return decodedToken.iat * 1000 + expiresInMilliseconds;
//   }
//   if (decodedToken.exp) {
//     return decodedToken.exp * 1000;
//   }

//   return Date.now();
// }

// function parseDurationToMilliseconds(duration: string): number {
//   const durationRegex = /^(\d+)([smh])$/;
//   const match = duration.match(durationRegex);
//   if (!match) {
//     throw new Error(`Invalid duration format: ${duration}`);
//   }
//   const value = parseInt(match[1], 10);
//   const unit = match[2];
//   switch (unit) {
//     case "s":
//       return value * 1000;
//     case "m":
//       return value * 60 * 1000;
//     case "h":
//       return value * 60 * 60 * 1000;
//     default:
//       throw new Error(`Invalid duration unit: ${unit}`);
//   }
// }

// axiosServices.interceptors.request.use(
//   async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
//     console.log("config.url", config.url);
//     console.log("isExcludedUrl", excludedUrlPatterns.some((pattern) => pattern.test(config.url || "")));

//     const newConfig = setTokenInHeaders(config);

//     if (!excludedUrlPatterns.some((pattern) => pattern.test(config.url || ""))) {
//       const jwtToken = store.getState().authToken;
//       const { userId }: any = getClaimsFromToken(jwtToken.token);
//       if (userId) {
//         newConfig.data = { ...newConfig.data, userId };
//       }
//     }

//     return newConfig;
//   },
//   async (error: AxiosError) => {
//     if (error.response && error.response.status === 401) {
//       try {
//         return axiosServices(error.config);
//       } catch (refreshError) {
//         console.error("Token refresh failed:", refreshError);
//         throw refreshError;
//       }
//     }
//     if (error.response && error.response.status === 401) {
//       window.location.href = "/auth/login";
//     }

//     return Promise.reject(error);
//   }
// );

// async function refreshToken() {
//   const refreshToken = Cookies.get('refreshToken');
//   const { userId }: any = getClaimsFromToken(refreshToken);
//   if (!refreshToken || !userId) {
//     window.location.href = '/auth/login';
//   }
//   const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/refresh-token`, {
//     refreshToken,
//     userId,
//   });
//   // console.log(response, "response of refresh token --");
//   const newAccessToken = response.data.accessToken;
//   localStorage.setItem('accessToken', newAccessToken);
//   const endTime = new Date().getTime();
//   // console.log('After API call to refresh token:', endTime);

//   return newAccessToken;
// }

// // Function to set a timer for token refresh every 10 minutes
// function setRefreshTokenTimer() {
//   return setInterval(async () => {
//     try {
//       await refreshToken();
//     } catch (error) {
//       console.error('Error refreshing token:', error);
//     }
//   }, 10 * 60 * 1000);
// }

// let refreshTokenTimer: any;

// export function startTokenRefreshTimer() {
//   refreshTokenTimer = setRefreshTokenTimer();
//   const startTime = new Date().getTime();
// }

// export function stopTokenRefreshTimer() {
//   clearInterval(refreshTokenTimer);
// }

// if (!isPageReload() && isAuthenticated()) {
//   startTokenRefreshTimer();
// }

// export default axiosServices;








import axios, { AxiosError, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { notifyMessage } from "./toastNotify";
import { getClaimsFromToken } from "src/services/token/verifyToken";
import { jwtDecode } from "jwt-decode";

const isPageReload = () => {
  const navigation = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;

  return navigation?.type === "reload";
};

const isAuthenticated = () => {
  return !!Cookies.get("accessToken");
};

// Function to refresh the token
async function refreshToken() {
  const refreshToken = Cookies.get("refreshToken");
  const { userId }: any = getClaimsFromToken(refreshToken);

  if (!refreshToken || !userId) {
    window.location.href = "/auth/login";
  }

  const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/refresh-token`, {
    refreshToken,
    userId,
  });

  const newAccessToken = response.data.accessToken;
  localStorage.setItem("accessToken", newAccessToken);

  return newAccessToken;
}

// Function to set a timer for token refresh every 10 minutes
function setRefreshTokenTimer() {
  return setInterval(async () => {
    try {
      await refreshToken();
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  }, 10 * 60 * 1000);
}

const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

let refreshTokenTimer: any;

// List of API endpoints that don't require authentication
const publicEndpoints = [
  // Tenant creation and retrieval endpoints
  /^https?:\/\/(?:[a-zA-Z0-9.-]+|localhost)(?::\d+)?\/v1\/district\/register\/club$/,
  /^https?:\/\/(?:[a-zA-Z0-9.-]+|localhost)(?::\d+)?\/v1\/tenants\/get-tenant\/\d+$/,

  // Tenant editing endpoints
  /^https?:\/\/(?:[a-zA-Z0-9.-]+|localhost)(?::\d+)?\/tenants\/edit-tenant\/[a-zA-Z0-9]+$/,
  /^https?:\/\/(?:[a-zA-Z0-9.-]+|localhost)(?::\d+)?\/admin\/v1\/tenants\/edit-tenant\/\d+$/,

  // Club-related URLs
  /^https?:\/\/[^\/]+\/da\/create\/club(?:\?expiration=.*)?$/,
  /^https?:\/\/[^\/]+\/da\/edit\/club(?:\/\d+)?(?:\?expiration=.*)?$/,

  // Club-related URLs
  /^https?:\/\/[^\/]+\/da\/create\/districtClub(?:\?expiration=.*)?$/,
  /^https?:\/\/[^\/]+\/da\/edit\/districtClub(?:\/\d+)?(?:\?expiration=.*)?$/,

  // State-related URLs
  /^https?:\/\/[^\/]+\/da\/create\/district(?:\?expiration=.*)?$/,
  /^https?:\/\/[^\/]+\/da\/edit\/district(?:\/\d+)?(?:\?expiration=.*)?$/,
];

// Request interceptor to attach Authorization header
axiosServices.interceptors.request.use(
  (config: any) => {
    const isPublicAPI = publicEndpoints.some(
      (publicURL) => config.url === publicURL
    );

    if (!isPublicAPI) {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    return config;
  },
  (error) => {
    if (!isPageReload()) {
      window.location.href = "/auth/login";
    }

    return Promise.reject(error);
  }
);

// Response interceptor to handle token refresh on 401 errors
axiosServices.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        const newToken = await refreshToken();
        error.config.headers["Authorization"] = `Bearer ${newToken}`;

        return axiosServices(error.config);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export function startTokenRefreshTimer() {
  refreshTokenTimer = setRefreshTokenTimer();
}

export function stopTokenRefreshTimer() {
  clearInterval(refreshTokenTimer);
}

if (!isPageReload() && isAuthenticated()) {
  startTokenRefreshTimer();
}

export default axiosServices;
