import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { IconEye } from '@tabler/icons-react';
import { useSelector } from 'react-redux'
import BackLink from 'src/components/back-link';
import PageContainer from 'src/components/page-container/PageContainer';
import DataTable from 'src/components/table/TableComponent';
import APP_ROUTES from 'src/routes/routePaths';
import { AppState } from 'src/store/Store'
import axiosServices from 'src/utils/axios';
import { notifyMessage } from 'src/utils/toastNotify';

interface ViewUploaded {
    url: string | null;
    type: string | null;
}

function ClubChangeRequestList() {
    const changeRequest: any = useSelector((state: AppState) => state.athelete.DistrictAthlete)
    const [rows, setRows] = useState<object[]>([])
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [openImageDialog, setOpenImageDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [viewImage, setViewImage] = useState<ViewUploaded>({ url: null, type: null });
    const [loading, setLoading] = useState(true);

    console.log("ChangeRequest List", changeRequest);
    const fetchClubChangeRequests = async () => {
        try {
            const result = await axiosServices.get(`/district/athlete/view-athlete-club-change-requests/${changeRequest?.athleteId}`)
            if (result.data.success) {
                const data = result.data.data
                console.log("data", data);
                setRows(data);
            }
        } catch (error: any) {
            // notifyMessage.error(error?.response?.data?.message);
            setRows([])
        }
    }

    useEffect(() => {
        fetchClubChangeRequests()
    }, [])

    const handleOpenDialog = (row: any) => {

        console.log("Row Data:", row);
        setSelectedRow(row);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedRow(null); // Clear data
    };

    const toggleImageDialog = (imageUrl?: string) => {
        if (imageUrl) {
            setSelectedImage(imageUrl);
            generateFileUrl(imageUrl, setViewImage);
            setOpenImageDialog(true);
        } else {
            setSelectedImage("");
            setOpenImageDialog(false);
            setViewImage({ url: null, type: null });
        }
    };

    const columns: GridColDef[] = [
        {
            field: "srno",
            headerName: "Sr no.",
            flex: 0.4,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: "full_name",
            headerName: "Athlete Name",
            flex: 0.6,
            renderCell: (params) => (<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2' }}> {params?.row?.full_name} </div>)
        },
        {
            field: "current_club_name",
            headerName: "Current Club Name",
            headerAlign: 'center',
            flex: 0.4,
            renderCell: (params) => (
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}
                    title={params?.row?.current_club_name}
                >
                    {params?.row?.current_club_name}
                </Box>
            ),
        },
        {
            field: "requested_club_name",
            headerName: "Requested Club Name",
            flex: 0.5,
            headerAlign: 'center',
            renderCell: (params) => (
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}
                    title={params?.row?.requested_club_name}
                >
                    {params?.row?.requested_club_name}
                </Box>
            ),
        },
        {
            field: "requested_by_name",
            headerName: "Requested User Name",
            flex: 0.5,
            headerAlign: 'center',
            renderCell: (params) => (
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}
                    title={params?.row?.requested_by_name}
                >
                    {params?.row?.requested_by_name}
                </Box>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                const { status } = params.row;

                const getStatusDisplay = () => {
                    if (status === "Rejected") {
                        return <span style={{ color: '#fa896a' }}>Rejected</span>;
                    }
                    if (status === "Pending") {
                        return <span style={{ color: '#ff9800' }}>Pending</span>;
                    }
                    if (status === "Pending") {
                        return (
                            <span style={{ color: status === "Approved" ? '#13deb9' : '#fa896a' }}>
                                {status} by Club
                            </span>
                        );
                    }

                    if (status === "Approved") {
                        return (
                            <span style={{ color: '#13deb9' }}>
                                Approved by District
                            </span>
                        );
                    }

                    return <span>Unknown</span>;
                };

                return (
                    <div
                        style={{
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            lineHeight: '1.2',
                            textAlign: 'center',
                        }}
                    >
                        {getStatusDisplay()}
                    </div>
                );
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 0.3,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                const isBlocked = params.row.is_blocked;
                const reason = params.row.block_reason || "";
                const reason2 = params.row.unblock_reason || "";
                const blockTitle = isBlocked === 1
                    ? `Status: Blocked${reason ? `\nReason: ${reason}` : ''}`
                    : `Status: Unblocked${reason2 ? `\nReason: ${reason2}` : ''}`;
                const blockColor = isBlocked === 1 ? '#fa896a' : '#13deb9';

                return (
                    <>
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}
                        >
                            <Tooltip title="View Change Request">
                                <IconButton sx={{ cursor: "pointer" }} onClick={() => handleOpenDialog(params.row)} >
                                    <IconEye size="22" stroke={1.4} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </>
                );
            }
        }
    ];

    const rowss = rows.map((val: any, index: any) => ({
        id: val.request_id,
        srno: index + 1,
        full_name: `${val.first_name} ${val.last_name}`,
        current_club_name: val.current_club_name, requested_club_name: val.requested_club_name,
        requested_by_name: val.requested_by_name,
        created_at: val.created_at,
        noc: val.noc,
        status: val.request_status,
        ...rows,
    }));

    const generateFileUrl = (file: File | string | null, setUrl: (viewUploaded: ViewUploaded) => void) => {
        if (file instanceof File) {
            const url = URL.createObjectURL(file);
            const type = file.type;
            setUrl({ url, type });

            return () => URL.revokeObjectURL(url);
        } else if (typeof file === 'string') {
            const type = getFileTypeFromUrl(file);
            setUrl({ url: file, type });
        } else {
            setUrl({ url: null, type: null });
        }
    };

    const getFileTypeFromUrl = (url: string): string | null => {
        const extension = url.split('.').pop()?.toLowerCase();

        switch (extension) {
            case 'pdf':
                return 'application/pdf';
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            default:
                return null;
        }
    };

    return (
        <>
            <BackLink title="Back to the Club Change" route={`${APP_ROUTES.DISTRICT_ATHLETE_CLUB_CHANGE}`} />
            <PageContainer title="Club Change Request" description="This is Change Request page">
                <Stack
                    sx={{
                        background: "#ECF2FF",
                        borderRadius: "6px",
                        padding: "15px 30px",
                        minHeight: "unset",
                    }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h5">List of Club Change Request</Typography>
                </Stack>

                <DataTable rows={rowss} columns={columns} tableId="table16" />
            </PageContainer>

            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                <DialogTitle>Change Request Details</DialogTitle>
                <DialogContent dividers>
                    {selectedRow && (
                        <Grid container spacing={3} sx={{ flexDirection: { xs: 'column', sm: 'row' }, flexWrap: 'wrap' }}>

                            <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
                                <Box sx={{
                                    padding: '10px',
                                    background: '#f4f4f4',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    minHeight: '100px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Athlete Name</Typography>
                                    <Typography sx={{ wordBreak: 'break-word' }}>
                                        {selectedRow.full_name}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
                                <Box sx={{
                                    padding: '10px',
                                    background: '#f4f4f4',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    minHeight: '100px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Current Club</Typography>
                                    <Typography sx={{ wordBreak: 'break-word' }}>
                                        {selectedRow.current_club_name}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
                                <Box sx={{
                                    padding: '10px',
                                    background: '#f4f4f4',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    minHeight: '100px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Requested Club</Typography>
                                    <Typography sx={{ wordBreak: 'break-word' }}>
                                        {selectedRow.requested_club_name}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
                                <Box sx={{
                                    padding: '10px',
                                    background: '#f4f4f4',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    minHeight: '100px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Request Status</Typography>
                                    <Typography sx={{ wordBreak: 'break-word' }}>
                                        {selectedRow.status}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sx={{ flex: 1 }}>
                                <Box sx={{
                                    padding: '10px',
                                    background: '#f4f4f4',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    minHeight: '100px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                    width: '100%',
                                    wordWrap: 'break-word',
                                    whiteSpace: 'normal',
                                }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Reason for Club Change</Typography>
                                    <Typography sx={{ wordBreak: 'break-word' }}>
                                        {selectedRow[0].remarks || "N/A"}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
                                <Box sx={{
                                    padding: '10px',
                                    background: '#f4f4f4',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    minHeight: '100px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                    alignItems: 'flex-start',
                                }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>No Objection Certificate (NOC)</Typography>
                                    <Tooltip title="View Document">
                                        <Box display="flex" alignItems="center">
                                            <Typography sx={{ marginRight: 1 }}>View Document</Typography>
                                            <IconButton sx={{ cursor: 'pointer' }}>
                                                <IconEye size="22" stroke={1.0} onClick={() => toggleImageDialog(selectedRow.noc)} />
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} sx={{ flex: 1 }}>
                                <Box sx={{
                                    padding: '10px',
                                    background: '#f4f4f4',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ddd',
                                    '&:hover': {
                                        backgroundColor: '#eaeaea',
                                        cursor: 'pointer',
                                    },
                                    minHeight: '100px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    overflowWrap: 'break-word',
                                    alignItems: 'flex-start',
                                }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>Current Club Id Card</Typography>
                                    <Tooltip title="View Document">
                                        <Box display="flex" alignItems="center">
                                            <Typography sx={{ marginRight: 1 }}>View Document</Typography>
                                            <IconButton sx={{ cursor: 'pointer' }}>
                                                <IconEye size="22" stroke={1.0} onClick={() => toggleImageDialog(selectedRow[0].id_card)} />
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openImageDialog} onClose={() => toggleImageDialog()} fullWidth maxWidth="sm">
                <DialogTitle>Uploaded Document</DialogTitle>
                <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {viewImage?.url ? (
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            {loading && <CircularProgress />}
                            {viewImage?.type === "application/pdf" ? (
                                <iframe
                                    src={viewImage?.url}
                                    title="Uploaded Document"
                                    width="100%"
                                    height="500px"
                                    style={{ border: "none" }}
                                    onLoad={() => setLoading(false)}
                                />
                            ) : (
                                <img
                                    src={viewImage?.url}
                                    alt="Uploaded Document"
                                    style={{
                                        maxWidth: "90%",
                                        width: "auto",
                                        height: "auto",
                                        borderRadius: "8px",
                                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                                    }}
                                    onLoad={() => setLoading(false)}
                                />
                            )}
                        </Box>
                    ) : (
                        <Typography>No document available</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleImageDialog()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default ClubChangeRequestList