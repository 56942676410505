import {
    Button,
    OutlinedInput,
    FormControl,
    InputLabel,
    Select,
    Stack,
    InputAdornment,
    MenuItem,
    Box,
    Typography,
    Card,
    Grid,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CardContent,
    CardActions,
    Tooltip,
    IconButton,
    CircularProgress,
} from "@mui/material";
import TableHead from "src/components/table-head";
import { IconArrowRight, IconEye } from "@tabler/icons-react";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RequiredStar } from "src/components/required-star";
import { AppState, dispatch } from "src/store/Store";
import axiosServices from "src/utils/axios";
import CustomFormLabel from "src/utils/theme-elements/CustomFormLabel";
import CustomTextField from "src/utils/theme-elements/CustomTextField";
import { notifyMessage } from "src/utils/toastNotify";
import { nanoid } from "nanoid";
import axios from "axios";
import SportsIcon from "@mui/icons-material/Sports";
import { formatDate } from 'src/utils/basicFormaters';
import FileUpload from "src/components/upload-file/UploadFile";
import { allowedFormats, maxFileSize } from "src/utils/fileUploadConfig";
import { Link, Navigate, useNavigate } from "react-router-dom";
import APP_ROUTES from "src/routes/routePaths";
import { fetchDistrictAthleteListStart } from "src/store/reducers/atheleteSlice";
import BackLink from "src/components/back-link";

interface ViewUploaded {
    url: string | null;
    type: string | null;
}

const ClubChangeRequestForm = () => {
    console.log("Hiiii")
    const athletesList: any = useSelector((state: AppState) => state.athelete.districtAthleteList)
    console.log("athletes", athletesList)
    const [athleteDetails, setAthleteDetails] = useState<any>({
        id: null,
        firstName: "",
        lastName: ""
    })
    const [athleteId, setAthleteId] = useState<number | null>(null);
    const [districtAdminId, setDistrictAdminId] = useState("");
    const [athletes, setAthletes] = useState([]);
    const [currentClubId, setCurrentClubId] = useState("");
    const [newClubId, setNewClubId] = useState("");
    const [clubs, setClubs] = useState([]);
    const [reasonForChange, setReasonForChange] = useState("");
    const [noc, setNoc] = useState<any>(null);
    const [nocUrl, setNocUrl] = useState<ViewUploaded>({ url: null, type: null });
    const [idCard, setIdCard] = useState<any>(null);
    const [idCardUrl, setIdCardUrl] = useState<ViewUploaded>({ url: null, type: null });

    const { role, token } = useSelector((state: AppState) => state.authToken);

    const handleUpdateAthleteDetails = () => {
        if (role == "district_admin" && token) {
            const decodedToken: any = jwtDecode(token);
            setDistrictAdminId(decodedToken?.userId);
        }
    }

    useEffect(() => {
        dispatch(fetchDistrictAthleteListStart());
    }, [dispatch]);

    const handleSetAthletesList = () => {
        setAthletes(athletesList)
    }

    const handleSetAthleteId = (id: any) => {
        const athlete: any = athletes.filter((athleteData: any) => athleteData.id == id)
        if (athlete && athlete.length) {
            setAthleteId(id)
            setCurrentClubId(athlete?.[0]?.clubId)
            setNewClubId("")
        }
    }

    const fetchClubsList = async () => {
        try {
            const response = await axiosServices.get("/district/fetch/clubs")
            if (response.data.success) {
                const data = response.data.data
                setClubs(data)
            }
        } catch (error: any) {
            notifyMessage.error(error?.response?.data?.message)
        }
    }

    const Navigate = useNavigate();

    const handleBack = () => {
        Navigate(APP_ROUTES.DISTRICT_ADMIN_CLUB_CHANGE);
    };

    useEffect(() => {
        handleSetAthletesList();
        fetchClubsList();
        handleUpdateAthleteDetails();
    }, []);

    const handleFileUpload = (fileType: "noc" | "idCard") => (files: File[] | null) => {
        if (files && files.length > 0) {
            const uploadedFile = files[0];

            if (fileType === "noc") {
                setNoc(uploadedFile);
            } else if (fileType === "idCard") {
                setIdCard(uploadedFile);
            }
        }
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append("athlete_id", String(athleteId));
            formData.append("current_club_id", currentClubId);
            formData.append("requested_club_id", newClubId);
            formData.append("requested_by", districtAdminId);
            // formData.append("athlete_club_change_fees_configuration_id", clubChangeFees.athlete_club_change_fees_configuration_id);
            // formData.append("txnid", transactionId);
            formData.append("reason_for_club_change", reasonForChange);

            if (noc) {
                formData.append("noc", noc);
            }
            if (idCard) {
                formData.append("id_card", idCard);
            }
            const response = await axiosServices.post(`/district/athlete/request-club-change`, formData);
            if (response.data.success) {
                notifyMessage.success(response?.data?.message)
                setClubs([]);
                setAthleteId(null);
                setAthleteDetails({});
                setReasonForChange("");
                window.history.back();
            }
        } catch (error: any) {
            console.error("Error Response: ", error);
            notifyMessage.error(error?.response?.data?.error);
        }
    };

    const generateFileUrl = (file: File | string | null, setUrl: (viewUploaded: ViewUploaded) => void) => {
        if (file instanceof File) {
            const url = URL.createObjectURL(file);
            const type = file.type;
            setUrl({ url, type });

            return () => URL.revokeObjectURL(url);
        } else if (typeof file === 'string') {
            const type = getFileTypeFromUrl(file);
            setUrl({ url: file, type });
        } else {
            setUrl({ url: null, type: null });
        }
    };

    const getFileTypeFromUrl = (url: string): string | null => {
        const extension = url.split('.').pop()?.toLowerCase();

        switch (extension) {
            case 'pdf':
                return 'application/pdf';
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            default:
                return null;
        }
    };

    useEffect(() => {
        generateFileUrl(noc, setNocUrl);
    }, [noc]);

    useEffect(() => {
        generateFileUrl(idCard, setIdCardUrl);
    }, [idCard]);

    return (
        <>
            <BackLink title="Back to the Club Change List" route={`${APP_ROUTES.DISTRICT_ADMIN_CLUB_CHANGE}`} />
            <TableHead title={"Club Change Request Form"} />
            <Card sx={{ padding: 3 }}>
                <Grid container spacing={5} sx={{ marginBottom: 0 }}>
                    <Grid item xs={4}>
                        <CustomFormLabel>Athlete Name <RequiredStar /></CustomFormLabel>
                        <CustomTextField
                            select
                            name="athleteName"
                            fullWidth
                            value={athleteId}
                            onChange={(e: any) => {
                                handleSetAthleteId(e.target.value)
                            }
                            }
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                },
                            }}
                        >
                            {athletes.map((athlete: any) => (
                                <MenuItem key={athlete.id} value={athlete.id}>
                                    {`${athlete.firstName} ${athlete.lastName}`}
                                </MenuItem>
                            ))}
                        </CustomTextField>
                    </Grid>
                    <Grid item xs={4}>
                        <CustomFormLabel>Current Club <RequiredStar /></CustomFormLabel>
                        <CustomTextField
                            select
                            name="currentClub"
                            fullWidth
                            value={currentClubId}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                },
                            }}
                            disabled={true}

                        >
                            {clubs && clubs.map((club: any) => (
                                <MenuItem key={club.clubId} value={club.clubId}>{club?.clubName}</MenuItem>
                            ))}
                        </CustomTextField>
                    </Grid>
                    <Grid item xs={4}>
                        <CustomFormLabel>New Club <RequiredStar /></CustomFormLabel>
                        <CustomTextField
                            select
                            name="newClub"
                            fullWidth
                            value={newClubId}
                            onChange={(e: any) => setNewClubId(e.target.value)}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                },
                            }}
                        >
                            {clubs?.filter((club: any) => club.clubId != currentClubId && club.status == "Approved").map((val: any) => (
                                <MenuItem key={val.clubId} value={val.clubId}>{val.clubName}</MenuItem>
                            ))}
                        </CustomTextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: 0 }}>
                    <Grid item xs={12} >
                        <CustomFormLabel>Reason For Club Change <RequiredStar /></CustomFormLabel>
                        <CustomTextField
                            type="string"
                            name="reasonForChange"
                            value={reasonForChange}
                            onChange={(e: any) => setReasonForChange(e.target.value)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FileUpload
                            title="Current Club NOC"
                            name="noc"
                            viewUploaded={nocUrl}
                            required={true}
                            onFileUpload={handleFileUpload('noc')}
                            maxFileSize={maxFileSize.TwoMB}
                            allowedFormats={allowedFormats.ImageAndPDF}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FileUpload
                            title="Current Club ID card"
                            name="idCard"
                            viewUploaded={idCardUrl}
                            required={true}
                            onFileUpload={handleFileUpload('idCard')}
                            maxFileSize={maxFileSize.FiveMB}
                            allowedFormats={allowedFormats.ImageAndPDF}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ mt: 2 }}>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 2 }}>
                        <Button type="submit" variant="contained" disabled={!athleteId || !newClubId || !currentClubId || !noc || !idCard} onClick={handleSubmit}>
                            <Typography variant="subtitle2" mr={1}>
                                Submit
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Card >
        </>
    )
}

export default ClubChangeRequestForm