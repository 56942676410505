import React, { useEffect, useState } from "react";
import fees from "src/assets/images/athlete-id-card/reg-fees.png";
import idCard from "src/assets/images/athlete-id-card/id.png";
import mail from "src/assets/images/athlete-id-card/mailtemp5.png";
import uniqueId3 from "src/assets/images/athlete-id-card/UniqueId3.png";
import competition from "src/assets/images/configurations/comp2.png"
import { Avatar, Box, CardContent, Divider, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import BlankCard from "src/components/shared/BlankCard";
import APP_ROUTES from "src/routes/routePaths";
import TableHead from "src/components/table-head";
import { useSelector } from "react-redux";
import { AppState, useDispatch } from "src/store/Store";
import { setAdminType } from "src/store/reducers/TenentSlice";
import axiosServices from "src/utils/axios";
import { notifyMessage } from "src/utils/toastNotify";

const cardData = [
    {
        title: "Club Change Request Form",
        link: `${APP_ROUTES.DISTRICT_ATHLETE_CLUB_CHANGE}/club-change-request-form`,
        image: fees,
    },
    {
        title: "View Club Change Requests",
        link: `${APP_ROUTES.DISTRICT_ATHLETE_CLUB_CHANGE}/club-change-requests`,
        image: idCard,
    }
];

const ClubChangeRequest = () => {
    const [clubChangeDetails, setClubChangeDetails] = useState<any>(null)

    const athlete: any = useSelector((state: AppState) => state.athelete.DistrictAthlete)
    const fetchAtheteChangeRequestDetais = async () => {
        try {
            const response = await axiosServices.get(`/district/athlete/view-club-change-request/${athlete?.id}`)
            if (response.data.success) {
                const data = response.data.data
                setClubChangeDetails(data)
            }
        } catch (error: any) {
            // notifyMessage.error(error?.response?.data?.message)
        }
    }

    useEffect(() => {
        fetchAtheteChangeRequestDetais()
    }, [])

    console.log("clubChangeDetails", clubChangeDetails);



    return (<>
        <TableHead title={"Club Change Request"} />
        <Grid container spacing={3} mt={1} sx={{ width: "100%" }}>
            {/* {cardData?.map((card: any, index: any) => (
                <Grid key={index} item xs={12} sm={3} md={3}>
                    <BlankCard>
                        <CardContent sx={{ cursor: clubChangeDetails && clubChangeDetails.request_status == "Pending" && index === 0 ? "not-allowed" : "pointer", opacity: clubChangeDetails && clubChangeDetails.request_status == "Pending" && index === 0 ? 0.5 : 1 }}>
                            {index === 0 && clubChangeDetails && clubChangeDetails.request_status == "Pending" ? (
                                // If it's the first link and `clubChangeDetails` is not empty, render text without a clickable link
                                <Stack direction={"column"} gap={2} alignItems="center">
                                    <Avatar
                                        alt={card.title}
                                        src={card.image}
                                        sx={{ width: "80px", height: "80px", boxShadow: "-1px 1px 8px #5d87ff9c" }}
                                    />
                                    <Box textAlign={"center"}>
                                        <Typography variant="subtitle2">{card.title}</Typography>
                                    </Box>
                                </Stack>
                            ) : (
                                <Link style={{ color: "#000" }} to={card.link}>
                                    <Stack direction={"column"} gap={2} alignItems="center">
                                        <Avatar
                                            alt={card.title}
                                            src={card.image}
                                            sx={{ width: "80px", height: "80px", boxShadow: "-1px 1px 8px #5d87ff9c" }}
                                        />
                                        <Box textAlign={"center"}>
                                            <Typography variant="subtitle2">{card.title}</Typography>
                                        </Box>
                                    </Stack>
                                </Link>
                            )}
                        </CardContent>
                        <Divider />
                    </BlankCard>
                </Grid>
            ))} */}

            {cardData?.map((card: any, index: any) => (
                <Grid key={index} item xs={12} sm={3} md={3} >
                    <BlankCard>
                        <CardContent sx={{ cursor: "pointer" }}  >
                            <Link style={{ color: "#000" }} to={card.link}>
                                <Stack direction={"column"} gap={2} alignItems="center">
                                    <Avatar alt={card.title} src={card.image} sx={{ width: "80px", height: "80px", boxShadow: "-1px 1px 8px #5d87ff9c" }} />
                                    <Box textAlign={"center"}>
                                        <Typography variant="subtitle2">{card.title}</Typography>
                                    </Box>
                                </Stack>
                            </Link>
                        </CardContent>
                        <Divider />
                    </BlankCard>
                </Grid>
            ))}
        </Grid>
    </>
    );
}

export default ClubChangeRequest;